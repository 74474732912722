
import { defineComponent } from 'vue';
import Skill from '@/components/Skill.vue';
import Button from '@/components/Button.vue';

export default defineComponent({
  name: 'About',
  components: {
    Skill,
    Button
  },
  setup() {
    const tecSkills = [
      { name: 'Figma', skillPoints: 5 },
      { name: 'Sketch', skillPoints: 4 },
      { name: 'Balsamiq', skillPoints: 5 },
      { name: 'Axure', skillPoints: 4 },
      { name: 'Illustrator', skillPoints: 3 },
      { name: 'InDesign', skillPoints: 3 },
      { name: 'Photoshop', skillPoints: 3 },
      { name: 'Office', skillPoints: 5 },
      { name: 'HTML / CSS', skillPoints: 5 },
      { name: 'Angular', skillPoints: 4 },
      { name: 'Vue', skillPoints: 3 },
      { name: 'TypeScript', skillPoints: 4 },
      { name: 'JavaScript', skillPoints: 4 },
      { name: 'IntelliJ IDEA', skillPoints: 4 },
      { name: 'Windows', skillPoints: 5 },
      { name: 'Mac OS', skillPoints: 4 },
    ]

    const uxSkills = [
      { name: 'User Research', skillPoints: 3 },
      { name: 'Prototyping', skillPoints: 5 },
      { name: 'Interaction Design', skillPoints: 5 },
      { name: 'Visual Design', skillPoints: 4 },
      { name: 'Usability Testing', skillPoints: 3 },
    ]

    const languageSkills = [
      { name: 'Deutsch', skillPoints: 5 },
      { name: 'Englisch', skillPoints: 4 },
      { name: 'Französisch', skillPoints: 3 },
    ]

    const softSkills = [
      { name: 'Zuverlässig', skillPoints: 0 },
      { name: 'Ausdauernd', skillPoints: 0 },
      { name: 'Exakt', skillPoints: 0 },
      { name: 'Motiviert', skillPoints: 0 },
      { name: 'Kundenfreundlich', skillPoints: 0 },
      { name: 'Belastbar', skillPoints: 0 },
      { name: 'Flexibel', skillPoints: 0 },
      { name: 'Teamorientiert', skillPoints: 0 },
      { name: 'Wissbegierig', skillPoints: 0 },
    ]

    return {
      tecSkills, uxSkills, languageSkills, softSkills
    }
  }
});
