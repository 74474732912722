<template>
  <section id="about" class="min-h-screen max-w-7xl mx-auto px-4 sm:px-6 flex flex-col justify-start items-center">
    <div class="pt-24">
      <div class="opacity-0" v-scrollanimation="{enter: 'fadeInUp', once: true}">
        <h2 class="text-dfink-white text-3xl font-black">ÜBER MICH</h2>
        <div class="border-t-2 border-solid mx-auto w-16 mt-4"></div>
      </div>
    </div>

    <p class="text-dfink-white text-base 2xl:text-lg mt-16 3xl:mt-24 text-center opacity-0" v-scrollanimation="{enter: 'fadeInUp', once: true}">Ich bin Daniel Fink und User Experience Architect. Ich liebe es, schöne aber
      dennoch funktionelle Web-Applikationen sowie digitale Interfaces aller Art zu
      erstellen. Dabei ist es mir wichtig, die Kunden in ihrer Zielerreichung zu unterstützen
      und ihre Produkte entsprechend auszubauen. Es ist mir ein grosses
      Anliegen, mich mit einem Produkt identifizieren zu können. Deshalb begleite ich
      Projekte und Produkte gerne von Anfang an bis zum Ende. Als designaffiner
      Mensch mit Entwickler-Hintergrund möchte ich stetig an neuen Herausforderungen
      wachsen, dazulernen und Neues ausprobieren. Ich bin ein Teamplayer und liebe
      es mit verschiedenen Menschen etwas qualitativ Gutes auf die Beine zu stellen.</p>

    <div class="flex flex-col lg:flex-row w-full">
      <div class="mx-auto">
        <div class="mt-16 3xl:mt-24 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-y-16 gap-x-36">
          <!-- Technische Skills -->
          <div>
            <h3 class="text-dfink-white text-lg 2xl:text-xl font-bold text-center md1:text-left pb-2 opacity-0" v-scrollanimation="{enter: 'fadeInUp', once: true}">TECHNISCHE SKILLS</h3>
            <Skill v-for="skill in tecSkills" :key="skill.name" :text="skill.name" :skillPoints="skill.skillPoints" :maxPoints="5" class="pt-2"></Skill>
          </div>

          <!-- Fachliche Skills -->
          <div>
            <h3 class="text-dfink-white text-lg 2xl:text-xl font-bold text-center md1:text-left pb-2 opacity-0" v-scrollanimation="{enter: 'fadeInUp', once: true}">FACHLICHE SKILLS</h3>
            <Skill v-for="skill in uxSkills" :key="skill.name" :text="skill.name" :skillPoints="skill.skillPoints" :maxPoints="5" class="pt-2"></Skill>

            <h3 class="text-dfink-white text-lg 2xl:text-xl font-bold text-center md1:text-left pb-2 mt-16 md:mt-24 3xl:mt-32 opacity-0" v-scrollanimation="{enter: 'fadeInUp', once: true}">SPRACHEN</h3>
            <Skill v-for="skill in languageSkills" :key="skill.name" :text="skill.name" :skillPoints="skill.skillPoints" :maxPoints="5" class="pt-2"></Skill>
          </div>

          <!-- Softskills -->
          <div class="md:col-span-2 xl:col-span-1">
            <h3 class="text-dfink-white text-lg 2xl:text-xl font-bold text-center md1:text-left pb-2 opacity-0" v-scrollanimation="{enter: 'fadeInUp', once: true}">SOFTSKILLS</h3>
            <Skill v-for="skill in softSkills" :key="skill.name" :text="skill.name" :skillPoints="skill.skillPoints" :maxPoints="0" class="pt-2"></Skill>
          </div>
        </div>
      </div>
    </div>

    <Button :isNavigation="false" :url="'assets/Lebenslauf_Daniel_Fink_ohne_Referenzen.pdf'" :text="'Lebenslauf herunterladen'" class="mt-16 xl:mt-6 mb-40 opacity-0" v-scrollanimation="{enter: 'fadeInRight', once: true}"></Button>

  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Skill from '@/components/Skill.vue';
import Button from '@/components/Button.vue';

export default defineComponent({
  name: 'About',
  components: {
    Skill,
    Button
  },
  setup() {
    const tecSkills = [
      { name: 'Figma', skillPoints: 5 },
      { name: 'Sketch', skillPoints: 4 },
      { name: 'Balsamiq', skillPoints: 5 },
      { name: 'Axure', skillPoints: 4 },
      { name: 'Illustrator', skillPoints: 3 },
      { name: 'InDesign', skillPoints: 3 },
      { name: 'Photoshop', skillPoints: 3 },
      { name: 'Office', skillPoints: 5 },
      { name: 'HTML / CSS', skillPoints: 5 },
      { name: 'Angular', skillPoints: 4 },
      { name: 'Vue', skillPoints: 3 },
      { name: 'TypeScript', skillPoints: 4 },
      { name: 'JavaScript', skillPoints: 4 },
      { name: 'IntelliJ IDEA', skillPoints: 4 },
      { name: 'Windows', skillPoints: 5 },
      { name: 'Mac OS', skillPoints: 4 },
    ]

    const uxSkills = [
      { name: 'User Research', skillPoints: 3 },
      { name: 'Prototyping', skillPoints: 5 },
      { name: 'Interaction Design', skillPoints: 5 },
      { name: 'Visual Design', skillPoints: 4 },
      { name: 'Usability Testing', skillPoints: 3 },
    ]

    const languageSkills = [
      { name: 'Deutsch', skillPoints: 5 },
      { name: 'Englisch', skillPoints: 4 },
      { name: 'Französisch', skillPoints: 3 },
    ]

    const softSkills = [
      { name: 'Zuverlässig', skillPoints: 0 },
      { name: 'Ausdauernd', skillPoints: 0 },
      { name: 'Exakt', skillPoints: 0 },
      { name: 'Motiviert', skillPoints: 0 },
      { name: 'Kundenfreundlich', skillPoints: 0 },
      { name: 'Belastbar', skillPoints: 0 },
      { name: 'Flexibel', skillPoints: 0 },
      { name: 'Teamorientiert', skillPoints: 0 },
      { name: 'Wissbegierig', skillPoints: 0 },
    ]

    return {
      tecSkills, uxSkills, languageSkills, softSkills
    }
  }
});
</script>

<style scoped>
</style>

